import React from 'react';

import { PopUpQuizElement } from '@soomo/lib/types/WebtextManifest';

import BaseTable from './BaseTable';

import pageViewStyles from '../PageView.scss';

interface SelfAssessmentAggregated {
	completed: number;
	started: number;
}

interface Props {
	element: PopUpQuizElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: SelfAssessmentAggregated;
	totalStudentsCount?: number;
}

const SelfAssessment: React.FC<Props> = (props) => {
	const { element, isPageActivityLoaded, aggregatedElement, totalStudentsCount } = props;
	const { completed = 0, started = 0 } = aggregatedElement || {};

	const headerRow = ['Status', `Students (out of ${totalStudentsCount})`];
	const dataRows = [
		['Completed', completed],
		['Started but not completed', started],
		['Not started', totalStudentsCount - completed - started]
	];

	return (
		<div>
			<div className={pageViewStyles.ActivitySummary}>
				<span className={pageViewStyles.QuestionType}>Self Assessment</span>
			</div>
			<h2
				className={pageViewStyles.QuestionBody}
				dangerouslySetInnerHTML={{ __html: element.config.name }}
			/>

			<BaseTable header={headerRow} rows={dataRows} isDataLoaded={isPageActivityLoaded} />
		</div>
	);
};

export default SelfAssessment;
