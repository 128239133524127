import React, { FC } from 'react';

import { ExternalToolElement } from '@soomo/lib/types';
import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';

import GoReact from 'Containers/Views/Elements/ExternalTool/GoReact';
import { ElementActivity } from 'Types/index';

interface Props {
	external_tool: ExternalToolElement;
	activity: ElementActivity;
	inContext: boolean;
}

const ExternalTool: FC<Props> = (props) => {
	const { external_tool: externalTool, activity, inContext } = props;

	if ('score_config' in externalTool) {
		return (
			<GoReact element={externalTool as GoReactElement} activity={activity} inContext={inContext} />
		);
	}
	return (
		<>
			{inContext && (
				<div>
					<strong>[This content is unavailable in Analytics.]</strong>
				</div>
			)}
		</>
	);
};

export default ExternalTool;
