import React from 'react';

import { ExternalToolElement } from '@soomo/lib/types';
import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';

import AnalyticsLink from 'Components/AnalyticsLink';
import { Page } from 'Types/index';
import BaseTable from './BaseTable';

import pageViewStyles from '../PageView.scss';
import styles from '../AggregatedPageView.scss';
import InfoItem, { infoItemStyles } from './InfoItem';

interface ExternalToolAggregated {
	graded?: number;
	posted: number;
	avgGrade?: number;
}

interface Props {
	element: ExternalToolElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: ExternalToolAggregated;
	totalStudentsCount?: number;
	page: Page;
	uiActions: any;
}

const ExternalTool: React.FC<Props> = (props) => {
	const { element, isPageActivityLoaded, aggregatedElement, totalStudentsCount, page, uiActions } =
		props;
	const { graded = 0, posted = 0, avgGrade = 0 } = aggregatedElement || {};

	if (!('score_config' in element)) return;

	const isInstructorGrade =
		(element as GoReactElement).score_config.scoreOptions === 'instructorGrade';

	const headerRow = ['Status', `Students (out of ${totalStudentsCount})`];
	const dataRows = [
		...(isInstructorGrade
			? [
					['Graded', graded],
					['Posted but not yet graded', posted]
			  ]
			: [
					['Posted', posted] // completed
			  ]),
		['Not posted', totalStudentsCount - graded - posted]
	];

	const onViewAllRecordings = () => {
		uiActions.updateCurrentViewParams({
			scope: page.id,
			element: element.id,
			student: null,
			pageViewStudent: null
		});
	};

	return (
		<div>
			<div className={pageViewStyles.ActivitySummary}>
				<span className={pageViewStyles.QuestionType}>Video Recorder</span>
				<span>&nbsp;&mdash;&nbsp;</span>
				<AnalyticsLink variant="text-uppercase" onClick={onViewAllRecordings}>
					view all students&rsquo; recording
				</AnalyticsLink>
			</div>
			<h2
				className={pageViewStyles.QuestionBody}
				dangerouslySetInnerHTML={{ __html: element.title }}
			/>

			<BaseTable header={headerRow} rows={dataRows} isDataLoaded={isPageActivityLoaded} />

			<div className={styles.AggregatedQuestionInfo}>
				{isInstructorGrade && (
					<InfoItem
						label="Average grade:"
						data={isPageActivityLoaded ? `${Math.floor(avgGrade * 100)}%` : undefined}
					/>
				)}

				<div className={infoItemStyles}>
					<AnalyticsLink variant="text" onClick={onViewAllRecordings}>
						View all students&rsquo; recording
					</AnalyticsLink>
				</div>
			</div>
		</div>
	);
};

export default ExternalTool;
