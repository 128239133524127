import React, { FC, MouseEvent } from 'react';
import { PageElement } from '@soomo/lib/types';

import { Page } from 'Types/index';
import { useAppDispatch, useAppSelector } from 'Store/index';
import { selectRequiresGradingActionElementIds } from 'Store/selectors';
import { updateCurrentViewParams } from 'Actions/uiActions';

import styles from './ShowNeedsGrading.scss';

const ViewAllAnswersList: FC = () => {
	const requiresGradingActionElementIds = useAppSelector(selectRequiresGradingActionElementIds);
	return (
		<ul className={styles.ViewAllList}>
			{requiresGradingActionElementIds.map((elementId) => (
				<li key={elementId}>
					<ViewAllAnswersLink element_family_id={elementId} />
				</li>
			))}
		</ul>
	);
};

const ViewAllAnswersLink: FC<{ element_family_id: string }> = ({ element_family_id }) => {
	const dispatch = useAppDispatch();

	const element: PageElement = useAppSelector(
		(state) => state.entities.elements[element_family_id]
	);
	const page: Page = useAppSelector((state) => state.entities.pages[element.page_id]);

	const handleViewAllClick = (event: MouseEvent) => {
		event.preventDefault();
		event.stopPropagation(); // Shouldn't make the parent button clicked

		dispatch(
			updateCurrentViewParams({
				scope: page.family_id,
				element: element.family_id,
				student: null
			})
		);
	};

	return (
		<a href="#" onClick={handleViewAllClick}>
			VIEW ALL FOR {page.page_number ? `${page.page_number}: ` : ''}
			{element.title}
		</a>
	);
};

export default ViewAllAnswersList;
