import { createSelector } from 'reselect';
import { ExternalToolElement, PageElement } from '@soomo/lib/types';
import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';

import { RootState } from 'Store/index';
import { AnswerActivity, Page, PageEntities, PageMetricsEntities } from 'Types/index';
import { getAnswerGraded } from 'Containers/Views/Elements/ExternalTool/GoReact/QuestionView';

export const selectCourseId = (state: RootState) => state.entities.course.id;

export const selectGradingRequiredElements = createSelector<
	RootState,
	Record<string, PageElement>,
	Array<GoReactElement>
>(
	(state) => state.entities.elements,
	(elements) =>
		Object.values(elements)
			.map((element) => {
				if (element.type !== 'external_tool') return;

				const externalTool = element as ExternalToolElement;
				if (!('score_config' in externalTool)) return;

				const goReactTool = externalTool as GoReactElement;
				if (goReactTool.score_config.scoreOptions !== 'instructorGrade') return;

				return goReactTool;
			})
			.filter(Boolean)
);

export const selectGradingRequiredPages = createSelector<
	RootState,
	Array<GoReactElement>,
	PageEntities,
	Array<Page>
>(
	selectGradingRequiredElements,
	(state) => state.entities.pages,
	(gradingRequiredElements, pages) => {
		const gradingRequiredPagesIds = new Set(gradingRequiredElements.map(({ page_id }) => page_id));
		return [...gradingRequiredPagesIds].map((pageId) => pages[pageId]).filter(Boolean);
	}
);

export interface RequiresActionPageSummary
	extends Pick<Page, 'family_id' | 'chapter_id' | 'element_ids'> {
	student_ids: string[];
}

export const selectRequiresGradingActionPagesSummary = createSelector<
	RootState,
	Array<Page>,
	PageMetricsEntities,
	string[],
	Array<RequiresActionPageSummary>
>(
	selectGradingRequiredPages,
	(state) => state.entities.metrics,
	(state) => state.entities.studentIds,
	(gradingRequiredPages, metrics, studentIds) =>
		gradingRequiredPages
			.map(({ family_id: pageId, chapter_id, element_ids }) => {
				const pageMetricIds = studentIds.map((studentId) => `${studentId}:${pageId}`);
				const requiresActionMetricIds = pageMetricIds.filter(
					(pageMetricsId) => metrics[pageMetricsId]?.requires_action
				);
				return requiresActionMetricIds.length === 0 // No students require grading action for this page
					? null
					: {
							family_id: pageId,
							chapter_id,
							element_ids,
							student_ids: requiresActionMetricIds.map((metricId) => metricId.split(':')[0])
					  };
			})
			.filter(Boolean)
);

export const selectRequiresGradingActionPagesSummaryInChapter = createSelector<
	RootState,
	string,
	Array<RequiresActionPageSummary>,
	string,
	Array<RequiresActionPageSummary>
>(
	selectRequiresGradingActionPagesSummary,
	(_, chapterFamilyId) => chapterFamilyId,
	(gradingRequiredPages, chapterFamilyId) =>
		gradingRequiredPages.filter((page) => page.chapter_id === chapterFamilyId)
);

export interface RequiresActionElementSummary {
	family_id: string;
	answers: Array<AnswerActivity>;
}

const selectRequiresGradingActionElementsSummary = createSelector<
	RootState,
	Array<GoReactElement>,
	Record<string, AnswerActivity>,
	Record<string, string[]>,
	string[],
	Array<RequiresActionElementSummary>
>(
	selectGradingRequiredElements,
	(state) => state.entities.answers,
	(state) => state.entities.elementAnswerIds,
	(state) => state.entities.studentIds,
	(gradingRequiredElements, answers, elementAnswerIds, studentIds) =>
		gradingRequiredElements
			.map(({ family_id }) => family_id)
			.map((elementId) => {
				const answerIds = studentIds
					.map((studentId) => `${studentId}:${elementId}`)
					.flatMap((elementAnswerId) => elementAnswerIds[elementAnswerId])
					.filter(Boolean);
				if (answerIds.length === 0) return null; // No answers are left for the element

				const elementAnswers = answerIds.map((answerId) => answers[answerId]); // Answer is defined in the store
				const requiresActionAnswers = elementAnswers.filter(
					(answer) =>
						answer.posted_at && // Answer has been posted
						!getAnswerGraded(answer) // And awaits instructor's grading
				);
				if (requiresActionAnswers.length === 0) return null; // No answers require grading action for the element

				return {
					family_id: elementId,
					answers: requiresActionAnswers
				};
			})
			.filter(Boolean)
);

export const selectRequiresGradingActionAnswersCount = createSelector<
	RootState,
	Array<RequiresActionElementSummary>,
	number
>(selectRequiresGradingActionElementsSummary, (requiresActionElementsSummary) =>
	requiresActionElementsSummary.reduce((count, { answers }) => count + answers.length, 0)
);

export const selectRequiresGradingActionElementIds = createSelector<
	RootState,
	Array<RequiresActionElementSummary>,
	string[]
>(selectRequiresGradingActionElementsSummary, (requiresActionElementsSummary) =>
	requiresActionElementsSummary.map(({ family_id }) => family_id)
);

const selectRequiresGradingActionStudentIds = createSelector<
	RootState,
	Array<RequiresActionElementSummary>,
	string[]
>(selectRequiresGradingActionElementsSummary, (requiresActionElementsSummary) => {
	const requiresGradingActionUserIds = requiresActionElementsSummary.flatMap(({ answers }) =>
		answers.map(({ user_id }) => user_id)
	);
	return [...new Set(requiresGradingActionUserIds)];
});

/**
 * Optimization pattern "selector for selector"
 * Allows to run heavy selectors only when a certain condition is met
 * @see https://github.com/reduxjs/reselect/issues/7#issuecomment-294144945
 *
 * In case of `selectRequiresGradingActionStudentIds` - we won't select any user ids until the user clicks "Show Needs Grading" button
 */
const defaultSelector = () => null;
const selectAssignmentTypeFilterSelector = createSelector<
	RootState,
	string,
	typeof selectRequiresGradingActionStudentIds | typeof defaultSelector
>(
	(state) => state.ui.currentViewParams.assignmentTypeFilter,
	(assignmentTypeFilter) => {
		switch (assignmentTypeFilter) {
			case 'needsGrading':
				return selectRequiresGradingActionStudentIds;
			default:
				return defaultSelector;
		}
	}
);

export const selectAssignmentTypeFilterUserIds = (state: RootState) =>
	selectAssignmentTypeFilterSelector(state)(state);

export const selectSortedPagesIds = createSelector<
	RootState,
	Record<string, any>,
	Record<string, any>,
	Array<string>
>(
	(state) => state.entities.toc,
	(state) => state.entities.chapters,
	(toc, chapters) =>
		!toc?.chapter_ids
			? []
			: toc.chapter_ids.map((id) => chapters[id]).flatMap((chapter) => chapter.page_ids)
);
